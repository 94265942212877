<script>
export default {
  data() {
    return {
      activeIndex: '',
      collapse: true,
    }
  },
  computed: {
    visibleLoginButton() {
      return !['ad', 'group'].includes(this.$route.name)
    },
  },
  watch: {
    // 使用watch 监听$router的变化,
    $route(to) {
      // 激活Tab页
      this.activeIndex = to.path

      // 跳转页面时回到顶部
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
  },
  methods: {
    showMenu() {
      this.collapse = !this.collapse
    },
    toLogin() {
      window.open('https://hsqn.wf-sn.com/')
    },
    toRegister() {
      this.$router.push('/register')
    },
  },
}
</script>

<template>
  <div id="app">
    <div
      id="min-bar"
      class="absolute z-10 h-14 w-full flex items-center justify-center sm:hidden"
    >
      <img src="@/assets/logo.png" class="h-10" alt="">
    </div>
    <img
      src="@/assets/logo.png"
      class="absolute z-10 mx-auto ml-64 mt-2.5 hidden h-10 text-center xl:inline"
      alt=""
    >
    <div
      class="z-10 h-14 w-full flex items-center justify-between border-b px-4 sm:hidden"
      :class="{ 'absolute': collapse, 'bg-black': !collapse }"
    >
      <button
        class="z-10 h-11 w-11 rounded bg-transparent bg-transparent text-2xl text-gray-900"
        @click="showMenu"
      >
        <i
          class="el-icon-s-unfold transform transition-transform"
          :class="{ 'rotate-90': !collapse }"
        />
      </button>
      <button
        v-show="visibleLoginButton"
        class="right-0 z-10 h-10 w-24 border border-gray-500 rounded bg-transparent text-white"
        @click="toLogin"
      >
        登录
      </button>
    </div>
    <el-menu
      :default-active="activeIndex"
      :class="{ hidden: collapse }"
      text-color="#fff"
      active-text-color="#ff9500"
      background-color="#000000d3"
      router
    >
      <el-menu-item index="/">
        首页
      </el-menu-item>
      <el-submenu index="/product">
        <template #title>
          产品介绍
        </template>
        <el-menu-item index="/product/contract">
          合同管理
        </el-menu-item>
        <el-menu-item index="/product/order">
          订单管理
        </el-menu-item>
        <el-menu-item index="/product/project">
          项目管理
        </el-menu-item>
        <el-menu-item index="/product/labor">
          劳动合同
        </el-menu-item>
        <el-menu-item index="/product/debt">
          清欠专家
        </el-menu-item>
        <el-menu-item index="/product/archive">
          资料归档
        </el-menu-item>
        <el-menu-item index="/product/file">
          档案利用
        </el-menu-item>
        <el-menu-item index="/product/unit">
          交易单位
        </el-menu-item>
        <el-menu-item index="/product/chapter">
          用章管理
        </el-menu-item>
        <el-menu-item index="/product/report">
          报表分析
        </el-menu-item>
      </el-submenu>
      <el-submenu index="/solution">
        <template #title>
          解决方案
        </template>
        <el-menu-item index="/solution/finance">
          金融行业
        </el-menu-item>
        <el-menu-item index="/solution/government">
          政府行业
        </el-menu-item>
        <el-menu-item index="/solution/manufacture">
          制造行业
        </el-menu-item>
        <el-menu-item index="/solution/internet">
          互联网行业
        </el-menu-item>
      </el-submenu>
      <el-menu-item index="/customer">
        客户案例
      </el-menu-item>
      <el-menu-item index="/news">
        新闻动态
      </el-menu-item>
      <el-menu-item index="/about">
        关于我们
      </el-menu-item>
      <el-menu-item index="/open">
        开放平台
      </el-menu-item>
    </el-menu>
    <div>
      <el-menu
        :default-active="activeIndex"
        class="el-menu absolute z-10 hidden hidden w-full sm:inline-block sm:inline-block xl:pl-80"
        mode="horizontal"
        text-color="#fff"
        active-text-color="#ff9500"
        background-color="#0000"
        router
      >
        <el-menu-item index="/">
          首页
        </el-menu-item>
        <el-submenu index="/product">
          <template #title>
            产品介绍
          </template>
          <el-menu-item index="/product/contract">
            合同管理
          </el-menu-item>
          <el-menu-item index="/product/order">
            订单管理
          </el-menu-item>
          <el-menu-item index="/product/project">
            项目管理
          </el-menu-item>
          <el-menu-item index="/product/labor">
            劳动合同
          </el-menu-item>
          <el-menu-item index="/product/debt">
            清欠专家
          </el-menu-item>
          <el-menu-item index="/product/archive">
            资料归档
          </el-menu-item>
          <el-menu-item index="/product/file">
            档案利用
          </el-menu-item>
          <el-menu-item index="/product/unit">
            交易单位
          </el-menu-item>
          <el-menu-item index="/product/chapter">
            用章管理
          </el-menu-item>
          <el-menu-item index="/product/report">
            报表分析
          </el-menu-item>
        </el-submenu>
        <el-submenu index="/solution">
          <template #title>
            解决方案
          </template>
          <el-menu-item index="/solution/finance">
            金融行业
          </el-menu-item>
          <el-menu-item index="/solution/government">
            政府行业
          </el-menu-item>
          <el-menu-item index="/solution/manufacture">
            制造行业
          </el-menu-item>
          <el-menu-item index="/solution/internet">
            互联网行业
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="/customer">
          客户案例
        </el-menu-item>
        <el-menu-item index="/news">
          新闻动态
        </el-menu-item>
        <el-menu-item index="/about">
          关于我们
        </el-menu-item>
        <el-menu-item index="/open">
          开放平台
        </el-menu-item>
      </el-menu>
      <button
        class="absolute right-28 z-10 mr-4 mt-2 hidden h-10 w-24 border border-gray-500 rounded bg-transparent text-white"
        @click="toRegister"
      >
        注册
      </button>
      <button
        v-show="visibleLoginButton"
        class="absolute right-0 z-10 mr-4 mt-2 h-10 w-24 border border-gray-500 rounded bg-transparent text-white"
        @click="toLogin"
      >
        登录
      </button>
    </div>
    <router-view />
    <div class="bg-black">
      <div class="mx-auto max-w-7xl px-4 py-10">
        <div class="sm:flex sm:justify-between">
          <div class="text-center sm:text-left">
            <p class="text-3xl text-white font-bold leading-loose">
              华商千诺
            </p>
            <p class="text-sm text-white font-light leading-9">
              产品购买咨询
            </p>
            <div
              class="flex flex-col text-3xl text-yellow-400 font-bold italic sm:flex-row"
            >
              <div>0573-8271-8931</div>
              <div>（0573-8271-4898）</div>
            </div>
          </div>
          <div class="sm:flex sm:gap-4">
            <div>
              <p class="text-center text-xs text-white font-light leading-10">
                官方微信
              </p>
              <img
                src="./assets/wechat.png"
                alt="官方微信"
                class="m-auto w-28"
              >
            </div>
            <div>
              <p class="text-center text-xs text-white font-light leading-10">
                APP 下载
              </p>
              <img
                src="./assets/appAPK.png"
                alt="APP 下载"
                class="m-auto w-28"
              >
            </div>
          </div>
        </div>
      </div>
      <el-divider class="my-0 bg-gray-800" />
      <div class="mx-auto max-w-7xl px-4 py-8">
        <p class="pb-24 text-center text-xs text-white font-light">
          COPYRIGHT ©2003-2022 浙江万方软件有限公司
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">浙ICP备14021890号</a>
        </p>
      </div>
    </div>
  </div>
</template>

<style>
.banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.el-col.el-col-24.el-col-sm-12 .el-form-item__error {
  margin-left: 16px;
}

#mac .el-carousel__arrow {
  display: none;
}

.w-9.h-9.rounded {
  display: none;
}

#mac_c img {
  object-position: left;
}

.el-menu--popup {
  background-color: #000000d3 !important;
}
</style>
