import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@unocss/reset/tailwind-compat.css'
import 'uno.css'
import './plugins/element.js'
import './assets/iconfont.js'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  },
}).$mount('#app')
